<template>
  <!-- 发布协议 -->
  <div class="container">
    <a-page-header
      :title="title"
      :subTitle="subTitle"
      @back="() => $router.go(-1)"
    />
    <div class="main-content">
      <div class="body">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item label="类型">
            <a-select v-model="form.type" placeholder="请选择协议类型" disabled>
              <a-select-option
                v-for="item in types"
                :key="item.id"
                :value="item.id"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div class="form-model-item-flex">
            <a-form-model-item label="协议名称">
              <a-input
                placeholder="请输入协议名称"
                v-model="form.name"
                disabled
              />
            </a-form-model-item>
            <a-form-model-item
              class="form-model-item"
              ref="number"
              label="协议/合同编号"
              prop="number"
            >
              <a-input
                v-model="form.number"
                placeholder="请输入编号"
                @blur="
                  () => {
                    $refs.number.onFieldBlur()
                  }
                "
                :disabled="type === 'info'"
              />
            </a-form-model-item>
          </div>
          <a-form-model-item ref="context" label="协议正文" prop="context">
            <tinymce
              ref="editor"
              v-model="form.context"
              :disabled="type === 'info'"
            />
          </a-form-model-item>
          <a-form-model-item ref="version" label="版本号" prop="version">
            <a-input
              v-model="form.version"
              placeholder="请输入版本号"
              @blur="
                () => {
                  $refs.version.onFieldBlur()
                }
              "
              :disabled="type === 'info'"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              class="submit-btn"
              type="primary"
              @click="onSubmit"
              v-if="type === 'change'"
            >
              发布新版
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import tinymce from '../../components/tinymce'
let agreementId = null
export default {
  data() {
    return {
      title: '',
      subTitle: '',
      form: {
        id: undefined,
        number: '',
        context: '',
        version: ''
      },
      startAt: undefined,
      endAt: undefined,
      rules: {
        // 绑定地区
        id: [{ required: true, message: '请选择协议类型', trigger: 'change' }],
        number: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        context: [{ required: true, message: '请输入正文', trigger: 'change' }],
        version: [{ required: true, message: '请输入版本号', trigger: 'blur' }]
      },
      submitLoading: false,
      types: [
        {
          id: 1,
          text: '服务协议'
        },
        {
          id: 2,
          text: '授权协议'
        },
        {
          id: 3,
          text: '隐私协议'
        }
      ],
      type: ''
    }
  },
  components: {
    tinymce
  },
  created() {
    const query = this.$route.query
    agreementId = query.id
    this.type = query.type
    this.getAgreementInfo()
    if (query.type === 'info') {
      this.title = this.$getPageTitle()
    } else {
      this.title = '发布新版'
    }
  },
  methods: {
    getAgreementInfo() {
      const data = {
        id: agreementId
      }
      this.$axios.getAgreementInfo(data).then((res) => {
        const d = res.data.data
        delete d.createAt
        this.subTitle = d.name
        if (this.type === 'info') {
          this.form = d
        } else {
          this.form.id = d.id
          this.form.type = d.type
          this.form.name = d.name
        }
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          delete this.form.name
          delete this.form.type
          this.$axios.changeAgreement(this.form).then(() => {
            this.submitLoading = false
            this.$message.success('操作成功')
            setTimeout(() => {
              this.$router.go(-1)
            }, 500)
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.body {
  padding: 32px;
  background-color: #fff;
}

.ant-form-item,
.form-model-item-flex {
  display: flex;
}

.form-model-item-flex >>> .ant-form-item-control-wrapper {
  width: 368px;
}

.body >>> .ant-form-item-control-wrapper {
  flex: 1;
}

.body >>> .ant-form-item-label {
  width: 6em;
}

.form-model-item {
  margin-left: 24px;
}

.form-model-item >>> .ant-form-item-label {
  width: 9em;
}

.ant-select {
  width: 200px;
}

.submit-btn {
  margin-left: 5em;
}
</style>
